import React from 'react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { VideoBox, ImgBox, isImage, FeatureItem } from './shared'
import SwipeableViews from 'react-swipeable-views'
import SwipeIndicator from './swipe-indicator'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    media: {
      width: '100%',
      height: 'auto',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '37px',
      overflow: 'hidden',
    },
    frame: {
      position: 'absolute',
      zIndex: 0,
    },
    image: {
      width: '100%',
      objectFit: 'contain',
      overflow: 'hidden',
      '& >img': {
        borderRadius: 18,
        height: '100%',
        width: '100%',
      },
    },
    video: {
      '& >video': {
        objectFit: 'contain',
        width: '100%',
      },
      width: '100%',
      overflow: 'hidden',
    },
    holder: {
      width: '290px',
      margin: '0 auto',
    },
  }
})

const FramelessMobileView = ({
  handleSelect,
  features,
  filename,
  selected,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.media}>
        {isImage(filename) ? (
          <ImgBox classes={classes.image} src={filename} />
        ) : (
          <VideoBox classes={classes.video} src={filename} />
        )}
      </Box>
      <SwipeableViews
        onChangeIndex={(index) => {
          handleSelect(index)
        }}
        index={selected}
      >
        {features &&
          features.map((ft, index) => {
            return (
              <FeatureItem
                index={index}
                key={ft._uid}
                handleSelect={handleSelect}
                className={classes.holder}
              >
                {renderBlok(features[index], {
                  selected: selected === index,
                })}
              </FeatureItem>
            )
          })}
      </SwipeableViews>
      <SwipeIndicator
        featureCount={features.length}
        handleSelect={handleSelect}
        selected={selected}
      />
    </Box>
  )
}

export default FramelessMobileView
