import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => {
  return {
    box: {
      width: '290px',
      height: 'auto',
      minHeight: '10px',
      display: 'flex',
      justifyContent: 'flex-start',
      margin: '0 auto',
    },
    indicator: {
      height: '6px',
      width: '40px',
      marginRight: '10px',
      backgroundColor: ({ selected }) =>
        selected ? theme.palette.primary.main : '#C4C4C4',
    },
  }
})

const Indicator = ({ selected, handleSelect, index }) => {
  const classes = useStyles({ selected })
  return (
    <Box
      className={classes.indicator}
      onClick={() => {
        handleSelect(index)
      }}
    />
  )
}

const SwipeIndicator = (props) => {
  const classes = useStyles()
  const { selected, featureCount, handleSelect } = props

  return (
    <Box className={classes.box}>
      {Array.from(Array(featureCount).keys()).map((ft, index) => {
        return (
          <Indicator
            index={index}
            key={ft._uid}
            handleSelect={handleSelect}
            selected={selected === index}
          />
        )
      })}
    </Box>
  )
}

export default SwipeIndicator
